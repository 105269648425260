import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { Input, Button, message } from 'antd'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { AuthPasswordResetInterface } from '@/types'
import { resetPassword, verifyPasswordToken } from '@/states/actions'
import { AlertStatus } from '@/components/common/common.alert'

const initialPasswordResetValues: AuthPasswordResetInterface = {
  newPassword: '',
  newPasswordConfirm: '',
}

const ResetPassword = () => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const { t, i18n } = useTranslation()

  // State
  const [loading, setLoading] = useState<boolean>(false)
  const [email, setEmail] = useState(searchParams.get('email'))
  const [token, setToken] = useState(searchParams.get('token'))

  // Effect
  useEffect(() => {
    if (!token) {
      navigate('/login')
    }
  }, [token])

  // Validation
  const validationPasswordResetSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required(t('validation.required'))
      .min(8, t('validation.min', { len: 8 }))
      .max(30, t('validation.max', { len: 30 }))
      .matches(
        /^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/,
        t('validation.passwordPattern')
      ),
    newPasswordConfirm: Yup.string()
      .required(t('validation.required'))
      .min(8, t('validation.min', { len: 8 }))
      .max(30, t('validation.max', { len: 30 }))
      .matches(/^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/, t('validation.passwordPattern'))
      .oneOf([Yup.ref('newPassword'), null], t('validation.passwordNotMatch')),
  })

  // Formik
  const formikPasswordReset = useFormik({
    initialValues: initialPasswordResetValues,
    validationSchema: validationPasswordResetSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setStatus(null)

      verifyPasswordToken(token as string)
        .then((res) => {
          const tmpAccessToken = res.data.accessToken.token
          const req = {
            newPassword: values.newPassword,
            newPasswordConfirm: values.newPasswordConfirm,
          }

          resetPassword(req, tmpAccessToken)
            .then((res) => {
              message.success(t('auth.password.reset.resetCompleted'))

              setTimeout(() => {
                navigate('/login')
              }, 1000)
            })
            .catch((e) => {
              // No data
              if (e.response.status === 404) {
                setStatus(t('error.expiredToken'))
              } else {
                setStatus(t('error.wrong'))
              }

              setSubmitting(false)
              setLoading(false)

              setTimeout(() => {
                // navigate('/password/forget')
              }, 1000)
            })
        })
        .catch((e) => {
          // No data
          if (e.response.status === 500) {
            setStatus(t('error.expiredToken'))
          } else {
            setStatus(t('error.wrong'))
          }

          setSubmitting(false)
          setLoading(false)

          setTimeout(() => {
            navigate('/password/forget')
          }, 2000)
        })
    },
  })

  const pgTitle = `${t('auth.password.reset.title')} • ${
    process.env.REACT_APP_NAME
  }`

  return (
    <>
      <Helmet>
        <title>
          {t('auth.password.reset.title')} · {process.env.REACT_APP_NAME}
        </title>
      </Helmet>
      <div className="min-h-screen flex items-center justify-center ">
        <div className={'max-w-md w-full'}>
          <div className="pt-8 pb-5 px-16 space-y-5">
            {!process.env.REACT_APP_PROJECT_ID ? (
              <div className="flex justify-center pb-2">
                <div className="w-32 h-24 memex-logo vertical flex-none"></div>
              </div>
            ) : (
              <></>
            )}
            <div className={'space-y-1'}>
              <h2 className="text-center text-2xl leading-8 font-bold text-gray-900 mb-0">
                {t('auth.password.reset.title')}
              </h2>
              <p className={'text-center text-gray-500'}>
                {t('auth.password.reset.desc')}
              </p>
            </div>
            {/* Form - 시작 */}
            <form onSubmit={formikPasswordReset.handleSubmit} method="POST">
              <AlertStatus
                status={formikPasswordReset.status}
                onClick={() =>
                  formikPasswordReset.setStatus(null)
                }></AlertStatus>
              <div className="space-y-2">
                <div className="block">
                  <label htmlFor="newPassword" className="">
                    <div className={'mb-0.5'}>{t('newPassword')}</div>
                    <Input.Password
                      id="newPassword"
                      name="newPassword"
                      type="password"
                      onChange={formikPasswordReset.handleChange}
                      value={formikPasswordReset.values.newPassword}
                      placeholder={t('validation.password')}
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                    />
                  </label>
                  {formikPasswordReset.touched.newPassword &&
                  formikPasswordReset.errors.newPassword ? (
                    <p className="my-1 text-xs text-red-500">
                      {formikPasswordReset.errors.newPassword}
                    </p>
                  ) : null}
                </div>
                <div className="block">
                  <label htmlFor="newPasswordConfirm" className="">
                    <div className={'mb-0.5'}>{t('passwordConfirmed')}</div>
                    <Input.Password
                      id="newPasswordConfirm"
                      name="newPasswordConfirm"
                      type="password"
                      onChange={formikPasswordReset.handleChange}
                      value={formikPasswordReset.values.newPasswordConfirm}
                      placeholder={t('validation.password')}
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                    />
                  </label>
                  {formikPasswordReset.touched.newPasswordConfirm &&
                  formikPasswordReset.errors.newPasswordConfirm ? (
                    <p className="my-1 text-xs text-red-500">
                      {formikPasswordReset.errors.newPasswordConfirm}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="mt-4">
                <Button
                  type={'primary'}
                  htmlType={'submit'}
                  block
                  disabled={loading}
                  loading={loading}>
                  {t('auth.password.reset.reset')}
                </Button>
              </div>
            </form>
            {/* Form - 끝 */}
          </div>
          <div className={'text-center'}>
            <Link to="/login">
              <span className="text-xs text-gray-500 leading-5 hover:underline cursor-pointer">
                {t('auth.login.title')}
              </span>
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default ResetPassword
