import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { Layout, Button, Tooltip } from 'antd'
import {
  PlusCircleOutlined,
  ApiOutlined,
  InfoCircleOutlined,
  CloudSyncOutlined,
  HolderOutlined,
} from '@ant-design/icons'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import axios from 'axios'
import { RootState } from '@/states/reducers'
const { Sider } = Layout
import {
  LAYOUT_DEFAULT_BG,
  LAYOUT_OKAY_COLOR,
  LAYOUT_SUCCESS_COLOR,
  LAYOUT_WARNING_COLOR,
} from '@/configs'
import {
  setCategoryFormModal,
  setModelFormModal,
} from '@/states/actions/modals.actions'
import {
  getModels,
  setCurrentModel,
  setModelsList,
  updateModel,
} from '@/states/actions/models.actions'
import { useAppDispatch } from '@/states/store'
import {
  getCategoryGroups,
  setCategoriesList,
  updateCategoryGroup,
} from '@/states/actions/categories.actions'

export const LayoutSidebarProjectContentsBuilder = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  // State (Redux)
  const { layoutState, projectsState } = useSelector(
    (state: RootState) => ({
      layoutState: state.layout,
      projectsState: state.projects,
    }),
    shallowEqual
  )
  const { sidebar } = layoutState
  const {
    modelList,
    currentModel,
    currentProject,
    tmpModel,
    contentsListLoading,
    categoriesListInit,
    categoriesList,
  } = projectsState

  /**
   * 모델 변경
   * @param e
   * @returns
   */
  const onModelChange = (model) => {
    if (contentsListLoading) return

    const targetModelId = model.id
    const targetModel = modelList.find((m) => m.id === targetModelId)

    if (
      currentModel?.id === targetModelId ||
      (JSON.stringify(currentModel) !== JSON.stringify(tmpModel) &&
        !confirm(t('confirmNotSaveRecently')))
    ) {
      return false
    }

    navigate(`/projects/${currentProject?.uid}/builder/${targetModel?.id}`)
    dispatch(setCurrentModel(targetModel))
  }

  /**
   * 모델 순서 변경
   * @param result
   * @returns
   */
  const onSortModelEnd = async (result) => {
    if (!result.destination || contentsListLoading) {
      return
    }

    // Sort 실행
    const updateModels = JSON.parse(JSON.stringify(modelList))
    console.log(updateModels)
    const startIndex = result.source.index
    const endIndex = result.destination.index
    const [removed] = updateModels.splice(startIndex, 1)
    updateModels.splice(endIndex, 0, removed)
    console.log(updateModels)
    await dispatch(setModelsList(updateModels))

    const categoryReqs: any[] = []
    await updateModels.forEach((model, idx) => {
      console.log(model)
      model.order = idx

      categoryReqs.push(updateModel(currentProject?.uid, model.id, model))
    })

    axios.all([...categoryReqs]).then(
      axios.spread((...responses) => {
        setTimeout(() => {
          dispatch(getModels(currentProject?.uid, currentModel?.id))
        })
      })
    )
  }

  /**
   * 카테고리 변경
   * @param e
   * @returns
   */
  const onCategoryChange = (category) => {
    if (contentsListLoading) return

    dispatch(setCategoryFormModal(true, category))
  }

  /**
   * 카테고리 순서 변경
   * @param result
   * @returns
   */
  const onSortCategoryEnd = async (result) => {
    if (!result.destination || contentsListLoading) {
      return
    }

    // Sort 실행
    const updateCategories = JSON.parse(JSON.stringify(categoriesList))
    console.log(updateCategories)
    const startIndex = result.source.index
    const endIndex = result.destination.index
    const [removed] = updateCategories.splice(startIndex, 1)
    updateCategories.splice(endIndex, 0, removed)
    await dispatch(setCategoriesList(updateCategories))

    const categoryReqs: any[] = []
    await updateCategories.forEach((category, idx) => {
      console.log(category)
      category.order = idx

      categoryReqs.push(
        updateCategoryGroup(currentProject?.uid, category.id, category)
      )
    })

    axios.all([...categoryReqs]).then(
      axios.spread((...responses) => {
        setTimeout(() => {
          // dispatch(getCategoryGroups(currentProject?.uid))
        })
      })
    )
  }

  return (
    <Sider
      theme={'light'}
      width={'auto'}
      className={'border-r border-gray-300 overflow-hidden overflow-y-auto'}
      style={{
        height: '100vh',
        backgroundColor: LAYOUT_DEFAULT_BG,
        zIndex: 100,
      }}>
      <h4
        className={
          'py-3 px-6 text-xs leading-5 mb-0 flex justify-between items-center'
        }>
        <span className="font-bold uppercase">{t('models')}</span>
        {currentProject?.usage?.type !== 'UNLIMITED' &&
        currentProject?.usage?.model ? (
          <Tooltip title={t('noModelsDesc')} className="cursor-help">
            <span
              className="font-normal flex space-x-1 items-center"
              style={{
                color:
                  modelList.length >= currentProject?.usage?.model.limit
                    ? LAYOUT_WARNING_COLOR
                    : LAYOUT_OKAY_COLOR,
              }}>
              <span>
                {modelList.length} / {currentProject?.usage?.model.limit}
              </span>
              <InfoCircleOutlined className="mt-px" />
            </span>
          </Tooltip>
        ) : (
          <></>
        )}
      </h4>
      <div>
        {currentProject ? (
          <DragDropContext onDragEnd={onSortModelEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  className="ant-menu ant-menu-root ant-menu-inline ant-menu-light"
                  role="menu"
                  style={{
                    backgroundColor: 'transparent',
                  }}
                  {...provided.droppableProps}
                  ref={provided.innerRef}>
                  {modelList.map((model, index) => (
                    <Draggable
                      key={model.id}
                      draggableId={(model.id as number).toString()}
                      index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}>
                          <div
                            className={`ant-menu-item ant-menu-item-only-child  ${
                              currentModel && model.id === currentModel.id
                                ? 'ant-menu-item-selected'
                                : ''
                            }`}
                            style={{
                              paddingLeft: '24px',
                            }}
                            role="menuitem"
                            onClick={() => onModelChange(model)}>
                            <div className="ant-menu-title-content">
                              <div className="flex items-center space-x-2 ">
                                <div className="grow truncate flex items-center space-x-2">
                                  <span>
                                    {
                                      model.languageMap[
                                        currentProject.defaultLang
                                      ]
                                    }
                                  </span>
                                  {model.lastValidate ? (
                                    <CloudSyncOutlined
                                      style={{
                                        color: LAYOUT_SUCCESS_COLOR,
                                      }}
                                    />
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                {model.hasTitle ? (
                                  <div className="flex-none">
                                    <ApiOutlined
                                      title={t('availableRelationConnected')}
                                    />
                                  </div>
                                ) : (
                                  <></>
                                )}
                                <div {...provided.dragHandleProps}>
                                  <HolderOutlined
                                    className="cursor-move"
                                    title={t('changeOrder')}
                                  />
                                </div>
                              </div>
                            </div>
                            {provided.placeholder}
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                  <div
                    className="ant-menu-item ant-menu-item-only-child cursor-pointer"
                    onClick={() => dispatch(setModelFormModal(true))}
                    style={{
                      paddingLeft: '24px',
                    }}>
                    {/* <Button
                      type={'link'}
                      icon={<PlusCircleOutlined />}
                      disabled={
                        currentProject?.usage &&
                        modelList.length >= currentProject.usage.model.limit
                      }
                      style={{
                        padding: 0,
                      }}>
                      {t('addModel')}
                    </Button> */}
                  </div>
                </div>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <></>
        )}
      </div>
      <div>
        <h4
          className={
            'py-3 px-6 text-xs leading-5 mb-0 flex justify-between items-center'
          }>
          <span className="font-bold uppercase">{t('category')}</span>
        </h4>
        {/* 카테고리 목록: 시작 */}
        <div>
          {currentProject ? (
            <DragDropContext onDragEnd={onSortCategoryEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    className="ant-menu ant-menu-root ant-menu-inline ant-menu-light"
                    role="menu"
                    style={{
                      backgroundColor: 'transparent',
                    }}
                    {...provided.droppableProps}
                    ref={provided.innerRef}>
                    {categoriesList.map((category, index) => (
                      <Draggable
                        key={category.id}
                        draggableId={(category.id as number).toString()}
                        index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}>
                            <div
                              className={`ant-menu-item ant-menu-item-only-child  ${
                                currentModel && category.id === currentModel.id
                                  ? 'ant-menu-item-selected'
                                  : ''
                              }`}
                              style={{
                                paddingLeft: '24px',
                              }}
                              role="menuitem"
                              onClick={() => onCategoryChange(category)}>
                              <div className="ant-menu-title-content">
                                <div className="flex items-center space-x-2 ">
                                  <div className="grow truncate flex items-center space-x-2">
                                    <span>
                                      {
                                        category.languageMap[
                                          currentProject.defaultLang
                                        ]
                                      }
                                    </span>
                                    <span>
                                      ({category.selectorList.length})
                                    </span>
                                  </div>
                                  <div {...provided.dragHandleProps}>
                                    <HolderOutlined
                                      className="cursor-move"
                                      title={t('changeOrder')}
                                    />
                                  </div>
                                </div>
                              </div>
                              {provided.placeholder}
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                    <div
                      className="ant-menu-item ant-menu-item-only-child cursor-pointer"
                      onClick={() => dispatch(setCategoryFormModal(true))}
                      style={{
                        paddingLeft: '24px',
                      }}>
                      <Button
                        type={'link'}
                        icon={<PlusCircleOutlined />}
                        style={{
                          padding: 0,
                        }}>
                        {t('addCategory')}
                      </Button>
                    </div>
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          ) : (
            <></>
          )}
        </div>
        {/* 카테고리 목록: 끝 */}
      </div>
    </Sider>
  )
}
