import React, { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Modal, Button, message } from 'antd'
import { CheckOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { RootState } from '@/states/reducers'
import { setMediaSelectorModal } from '@/states/actions/modals.actions'
import { LAYOUT_MODAL_WIDTH } from '@/configs'
import { MediaSelector } from './'
import { CellInterface, ComponentInterface, MediaInterface } from '@/types'
import {
  getContentsItem,
  updateContents,
  updateContentsItem,
} from '@/states/actions/contents.actions'
import { useAppDispatch } from '@/states/store'

interface MediaSelectorProps {
  onChangeContents?: () => void
}

export const MediaSelectorModal = ({
  onChangeContents,
}: MediaSelectorProps) => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  // State (Redux)
  const { projectsState, modalsState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
      modalsState: state.modals,
    }),
    shallowEqual
  )
  const { currentProject, currentModel, contentsList, flattenComponentList } =
    projectsState
  const { mediaSelectorModal, cellInfo, componentInfo, contentsInfo } =
    modalsState

  // State
  const [component, setComponent] = useState<
    ComponentInterface | null | undefined
  >(null)
  const [mediaList, setMediaList] = useState<MediaInterface[]>([])
  const [cell, setCell] = useState<CellInterface | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  // Effect
  useEffect(() => {
    if (cellInfo && flattenComponentList) {
      setComponent(
        flattenComponentList.find((comp) => comp.id === cellInfo?.component?.id)
      )
    }
  }, [cellInfo, flattenComponentList])

  useEffect(() => {
    if (mediaSelectorModal) {
      if (cellInfo) {
        setCell(cellInfo)

        if (cellInfo.mediaList) {
          setMediaList(cellInfo.mediaList)
        }
      } else if (componentInfo && contentsInfo) {
        setCell({
          // @ts-ignore
          component: {
            // @ts-ignore
            id: componentInfo?.id,
            order: componentInfo.order as number,
            type: componentInfo.type,
          },
          content: {
            // @ts-ignore
            uid: contentsInfo?.uid,
          },
          mediaList: [],
          option: componentInfo?.option,
        })
      }
    } else {
      setMediaList([])
    }
  }, [mediaSelectorModal])

  /**
   * 미디어 변경
   * @param componentId
   * @param key
   * @param value
   */
  const onCellChange = (value) => {
    if (cell) {
      const updatedCell = JSON.parse(JSON.stringify(cell))
      updatedCell.mediaList = value
      updatedCell.mediaIdList = value.map((m) => m.id)
      setCell(updatedCell)

      setMediaList(value)
    }
  }

  /**
   * 저장
   * @returns
   */
  const onSaveMedia = () => {
    if (!cell) return

    const updatedContents = JSON.parse(JSON.stringify(contentsList))
    const contentsItem = updatedContents.find(
      (cn) => cn.uid === contentsInfo?.uid
    )
    const contentsIdx = updatedContents.indexOf(contentsItem)
    const cellItem = contentsItem.cellList.find((cl) => cl.uid === cell.uid)
    const cellIdx = contentsItem.cellList.indexOf(cellItem)

    // 기존 cell
    if (cellIdx >= 0) {
      contentsItem.cellList[cellIdx]['mediaList'] = cell.mediaList
      contentsItem.cellList[cellIdx]['mediaIdList'] = cell.mediaIdList
    }
    // cell 추가
    else {
      contentsItem.cellList.push(cell)
    }

    contentsItem.cellList[cellIdx] = cellItem
    updatedContents[contentsIdx] = contentsItem

    setLoading(true)

    updateContents(
      currentProject?.uid,
      currentModel?.id,
      contentsItem.uid,
      contentsItem
    )
      .then((res) => {
        getContentsItem(currentProject?.uid, currentModel?.id, contentsItem.uid)
          .then((res) => {
            setLoading(false)
            dispatch(setMediaSelectorModal(false))
            dispatch(updateContentsItem(res.data.data))
          })
          .catch((e) => {
            message.error(e.response.data.error)
            setLoading(false)
          })
      })
      .catch((e) => {
        message.error(e.response.data.error)
        setLoading(false)
      })
  }

  return (
    <Modal
      width={LAYOUT_MODAL_WIDTH}
      closeIcon={<CloseCircleOutlined title={t('close')} />}
      open={mediaSelectorModal}
      onCancel={() => dispatch(setMediaSelectorModal(false))}
      title={t('editMediaContents')}
      footer={[
        <div key={'footer'} className={'flex justify-between items-center'}>
          <div>
            <Button
              type="primary"
              ghost
              onClick={() => dispatch(setMediaSelectorModal(false))}>
              {t('cancel')}
            </Button>
          </div>
          <div>
            <Button
              type={'primary'}
              icon={<CheckOutlined />}
              disabled={loading}
              loading={loading}
              onClick={() => onSaveMedia()}>
              {t('save')}
            </Button>
          </div>
        </div>,
      ]}>
      {currentModel && componentInfo ? (
        <div>
          <MediaSelector
            component={componentInfo}
            cell={cell}
            mediaList={mediaList}
            onSelect={(mediaList) => {
              onCellChange(mediaList)
            }}
          />
        </div>
      ) : (
        <></>
      )}
    </Modal>
  )
}
