import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

export const CommonLogo = () => {
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <div
      className={'py-4 px-6 border-b cursor-pointer'}
      onClick={() => navigate('/projects')}>
      <div className="w-full flex items-center space-x-3">
        <div className="w-6 h-6 flex-none">
          <img src="/android-chrome-192x192.png" alt="사료관리 시스템" />
        </div>
        <div className="grow overflow-hidden">
          <div className="leading-5 truncate">사료관리 시스템</div>
        </div>
      </div>
    </div>
  )
}
