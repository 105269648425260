export const DEFAULT_CONTENTS_PAGE_SIZE = 20
export const DEFAULT_MEDIA_PAGE_SIZE = 20
export const DEFAULT_TEXT_SIZE = 200
export const MAX_TEXT_SIZE = 200
export const CHUNK_CONTENTS_GET_SIZE = 500
export const CHUNK_SHEET_UPLOAD_SIZE = 20
export const DEFAULT_SORT = 'DATE_CREATE.DESC'
export const DEFAULT_PAGE_VALUE = {
  total: 0,
  pageSize: DEFAULT_CONTENTS_PAGE_SIZE,
  current: 1,
}

export const DEFAULT_TIMEZONE = 'Asia/Seoul'

/**
 * 커스텀 숨기는 모델
 */
export const HIDE_MODELS = [74, 64, 172]
