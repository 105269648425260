import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Input, Select } from 'antd'
import { RootState } from '@/states/reducers'
import { read, utils, writeFile } from 'xlsx'
import { message, Modal } from 'antd'
import moment from 'moment-timezone'
import axios from 'axios'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { v4 as uuidv4 } from 'uuid'
import {
  availableComponents,
  dateFormatReq,
  dateFormatsMoment,
  LAYOUT_DEFAULT_CELL_WIDTH,
  LAYOUT_EXCEL_WIDTH_DOWNLOAD_RATIO,
  CHUNK_SHEET_UPLOAD_SIZE,
  CHUNK_CONTENTS_GET_SIZE,
} from '@/configs'
import {
  setContentsListLoading,
  truncateContents,
  createContentsList,
  setContentsListReload,
  setContentsList,
  getContentsListSearch,
} from '@/states/actions/contents.actions'
import {
  CellInterface,
  CellValidationInterface,
  ContentsInterface,
  LanguageMap,
  ModelInterface,
  ProjectInterface,
  SelectorInterface,
} from '@/types'
import { checkCellValidation } from '@/utils/helpers'
import { getProjectUsage } from '@/states/actions/projects.actions'
import {
  createMedia,
  createMultiMedia,
  getMediaIdByFileName,
} from '@/states/actions/media.actions'
import { addItem, getItem, updateItem } from '@/utils/indexedDb'
import { useSearchParams } from 'react-router-dom'
import { useAppDispatch } from '@/states/store'

export const ContentsTools = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  // Params
  let { q } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()

  // State
  const [importReplace, setImportReplace] = useState<boolean>(false)
  const [massContents, setMassContents] = useState<any>(null)
  const [downloadContents, setDownloadContents] = useState<any>(null)
  const [searchField, setSearchField] = useState<string>('')
  const [keyword, setKeyword] = useState<string>('')

  // State (Redux)
  const { projectsState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
    }),
    shallowEqual
  )
  const {
    currentProject,
    currentModel,
    flattenComponentList,
    contentsPagination,
    contentsSort,
    contentsKeyword,
    checkedContentsList,
    allContentsList,
    modelList,
    contentsListLoading,
  } = projectsState

  // Effect
  useEffect(() => {
    if (flattenComponentList.length) {
      setSearchField(
        searchParams.get('q')
          ? (searchParams.get('q') as string).split('||')[0]
          : ''
      )

      setKeyword(
        searchParams.get('q')
          ? (searchParams.get('q') as string).split('||')[1]
          : ''
      )
    }
  }, [searchParams.get('q'), flattenComponentList])

  /**
   * 컬럼 길이 설정
   */
  const getColsWidth = () => {
    // Component Width
    const colsArr: any[] = []
    flattenComponentList
      .filter((fc) => fc.exportable)
      .forEach((fc) => {
        colsArr.push({
          wpx:
            (fc.option && fc.option.width
              ? (fc.option.width as number)
              : LAYOUT_DEFAULT_CELL_WIDTH) * LAYOUT_EXCEL_WIDTH_DOWNLOAD_RATIO,
        })
      })

    return colsArr
  }

  /**
   * 대량 컨텐츠 메뉴 선택
   * @param val
   */
  const onHandleSheet = (val) => {
    const fileSheet = document.getElementById('file-sheet')

    if (val === 'downloadSample') {
      exportSampleSheet()
    } else if (val === 'uploadReplace') {
      confirmImportReplace()
      setImportReplace(true)
    } else if (val === 'uploadAppend') {
      setImportReplace(false)
      fileSheet?.click()
    }

    setMassContents(null)
  }

  /**
   * 교체 확인
   */
  const confirmImportReplace = () => {
    Modal.confirm({
      centered: true,
      title: t('confirmUploadReplaceTitle'),
      icon: <ExclamationCircleOutlined />,
      content: t('confirmUploadReplaceDesc'),
      okText: t('upload'),
      cancelText: t('cancel'),
      onOk() {
        const fileSheet = document.getElementById('file-sheet')
        fileSheet?.click()
      },
      onCancel() {
        setImportReplace(false)
      },
    })
  }

  /**
   * 시트 업로드
   * @param e
   * @returns
   */
  const importSheet = async (e) => {
    if (!currentProject || !currentModel) return

    const fileSheet = document.getElementById('file-sheet') as HTMLInputElement
    const file = await e.target.files?.[0]?.arrayBuffer()
    const workbook = read(file, { type: 'array' })
    const results: any[] = []
    const sheets = workbook.Sheets

    // 다국어 시트 parse
    currentProject.languageList.forEach((lang, langIdx) => {
      results.push(
        utils.sheet_to_json(sheets[workbook.SheetNames[langIdx]], { header: 1 })
      )
    })

    let validationErrorArr: CellValidationInterface[] = []
    const components = flattenComponentList.filter((fc) => fc.exportable)
    const reqContentsArr: ContentsInterface[] = []
    const addedLength = results[0].length - 1
    const currentModelUsage = currentProject.usage?.modelList.find(
      (m) => m.id === currentModel.id
    )

    // 미디어 매칭용
    let mediaNameList: string[] = []
    let mediaIdListByFileName = {}

    // 연관콘텐츠 매칭용
    let titleUidList: string[] = []
    let relationUidKeyMap = {}

    // 전체 콘텐츠 갯수 확인
    if (
      currentProject.price !== 'UNLIMITED' &&
      currentProject.usage &&
      (importReplace ? 0 : currentProject.usage?.content.current) +
        addedLength >
        currentProject.usage?.content.limit
    ) {
      message.error(
        t('error.allContentsTooMany', {
          max: currentProject.usage?.content.limit,
        })
      )

      if (fileSheet) {
        fileSheet.value = ''
      }

      return false
    }

    // 모델별 콘텐츠 갯수 확인
    if (
      currentProject.price !== 'UNLIMITED' &&
      currentModelUsage &&
      (importReplace ? 0 : currentModelUsage.content.current) + addedLength >
        currentModelUsage.content.limit
    ) {
      message.error(
        t('error.contentsByModelTooMany', {
          max: currentModelUsage.content.limit,
        })
      )

      if (fileSheet) {
        fileSheet.value = ''
      }

      return false
    }

    message.loading(t('uploadingExcel'), 0)

    // 엑셀 데이터 파싱
    results[0].forEach((row, rowIdx) => {
      if (rowIdx) {
        const cellArr: CellInterface[] = []

        components.forEach((comp, colIdx) => {
          // Language Map
          const languageMap: LanguageMap = {}
          const type = comp.type
          const cellInfo: CellInterface = {
            componentId: comp.id as number,
            component: comp,
            type: comp.type,
            option: comp.option,
            value: null,
            languageMap: languageMap,
            selectorIdList: [],
            mediaIdList: [],
            relationUidList: [],
          }

          if (type === 'SINGLE_LINE_TEXT' || type === 'LONG_LINE_TEXT') {
            currentProject.languageList.forEach((lang, langIdx) => {
              // Convert Excel new line
              const cellText = results[langIdx][rowIdx][colIdx]
                ? (results[langIdx][rowIdx][colIdx] + '')
                    .split('')
                    .map((x) => (x.charCodeAt(0) === 8232 ? '\n' : x))
                    .join('')
                : ''

              languageMap[lang] =
                results[langIdx][rowIdx] && cellText
                  ? comp.option?.allowHtml
                    ? cellText.trim()
                    : cellText.trim().replace(/(<([^>]+)>)/gi, '')
                  : ''
            })
          } else if (type === 'RICH_TEXT') {
            currentProject.languageList.forEach((lang, langIdx) => {
              // Convert Excel new line
              let cellText = results[langIdx][rowIdx][colIdx]
                ? (results[langIdx][rowIdx][colIdx] + '')
                    .split('')
                    .map((x) => (x.charCodeAt(0) === 8232 ? '\n' : x))
                    .join('')
                : ''

              cellText = `{"time":${Date.now()},"blocks":[{"id":"${uuidv4()}","type":"paragraph","data":{"text":"${cellText
                .replace(/"/g, '\\"')
                .replace(
                  /<code>/gi,
                  "<code class='inline-code'>"
                )}"}}],"version":"2.25.0"}`

              languageMap[lang] =
                results[langIdx][rowIdx] && cellText
                  ? cellText.replace(/(?:\r\n|\r|\n)/g, '<br/>').trim()
                  : ''
            })
          } else if (type === 'MEDIA') {
            const fileNameText = results[0][rowIdx][colIdx]
              ? (results[0][rowIdx][colIdx] + '')
                  .split('')
                  .map((x) => (x.charCodeAt(0) === 8232 ? '\n' : x))
                  .join('')
              : ''

            mediaNameList = [
              ...mediaNameList,
              ...fileNameText.split(',').map((c) => c.normalize('NFC').trim()),
            ]
            cellInfo.tmpValue = fileNameText
              .split(',')
              .map((c) => c.normalize('NFC').trim())
          } else if (type === 'RELATION') {
            const titleText = results[0][rowIdx][colIdx]
              ? (results[0][rowIdx][colIdx] + '')
                  .split('')
                  .map((x) => (x.charCodeAt(0) === 8232 ? '\n' : x))
                  .join('')
              : ''

            titleUidList = [
              ...titleUidList,
              ...titleText.split(',').map((c) => c.normalize('NFC').trim()),
            ]
            cellInfo.tmpValue = titleText
              .split(',')
              .map((c) => c.normalize('NFC').trim())
          }

          if (type === 'EMAIL' || type === 'NUMBER' || type === 'PASSWORD') {
            cellInfo.value = row[colIdx] ? (row[colIdx] + '').trim() : null
          } else if (type === 'CATEGORY') {
            const selectorList: SelectorInterface[] = []
            const selectorIdList: number[] = []

            if (row[colIdx]) {
              const col = row[colIdx] + ''
              col.split(',').forEach((cateName) => {
                const selectorItem = comp.selectorList?.find(
                  (s) =>
                    s.languageMap[currentProject.defaultLang] ===
                    cateName.normalize('NFC').trim()
                )

                if (selectorItem && selectorItem.id) {
                  selectorList.push(selectorItem)
                  selectorIdList.push(selectorItem.id)
                }
              })
            }

            cellInfo.selectorList = selectorList
            cellInfo.selectorIdList = selectorIdList
          } else if (type === 'DATE' && row[colIdx]) {
            if (comp.option?.dateFormats === 'year') {
              cellInfo.value = moment(row[colIdx], 'YYYY').format(dateFormatReq)
            } else if (comp.option?.dateFormats === 'month') {
              cellInfo.value = moment(row[colIdx], 'YYYY-MM').format(
                dateFormatReq
              )
            } else if (comp.option?.dateFormats === 'date') {
              cellInfo.value = moment(
                new Date((row[colIdx] - (25567 + 1)) * 86400 * 1000)
              ).format(dateFormatReq)
            } else if (comp.option?.dateFormats === 'time') {
              cellInfo.value = moment(
                new Date((row[colIdx] - (25567 + 1)) * 86400 * 1000)
              )
                .tz('Europe/London')
                .format(dateFormatReq)
            }
          } else if (type === 'BOOLEAN') {
            cellInfo.value =
              typeof row[colIdx] !== 'undefined' &&
              row[colIdx] &&
              (row[colIdx] === 'O' ||
                row[colIdx] === 'o' ||
                row[colIdx] === '○' ||
                row[colIdx] === 'T' ||
                row[colIdx] === 'TRUE' ||
                row[colIdx] === 'True' ||
                row[colIdx] === 't' ||
                row[colIdx] === 'true')
          }

          cellArr.push(cellInfo)
        })

        let req = {
          order: rowIdx,
          cellList: cellArr,
        }

        reqContentsArr.push(req)

        // 필수 입력 확인
        cellArr.forEach((rc) => {
          validationErrorArr = [
            ...validationErrorArr,
            ...checkCellValidation(
              rc,
              currentProject as ProjectInterface,
              null,
              allContentsList,
              importReplace
            ),
          ]

          // 이전 id 추가
        })
      }
    })

    // Valid 확인
    if (validationErrorArr.length) {
      message.warning({
        content: (
          <ul className="text-left list-disc pl-4 mb-0">
            {validationErrorArr.map((error, eIdx) => (
              <li key={eIdx}>
                {t('error.' + error.errorType, { field: error.name })}
              </li>
            ))}
          </ul>
        ),
      })

      dispatch(setContentsListLoading(false))

      if (fileSheet) {
        fileSheet.value = ''
      }

      return false
    }

    dispatch(setContentsListLoading(true))

    // 기존 콘텐츠 삭제
    if (importReplace) {
      await truncateContents(currentProject.uid, currentModel.id)
    }

    mediaNameList = mediaNameList.filter((v, i, a) => a.indexOf(v) === i)

    function isValidUrl(string) {
      try {
        new URL(string)
        return true
      } catch (err) {
        return false
      }
    }

    const fileNameIdList = mediaNameList.filter((m) => m.split('.').length > 1)
    const urlNameList = mediaNameList.filter((m) => isValidUrl(m))

    // File 미디어 ID 가져오기
    if (fileNameIdList.length) {
      await getMediaIdByFileName(currentProject.uid, fileNameIdList).then(
        (res) => {
          mediaIdListByFileName = res.data
        }
      )
    }

    // URL Media 생성
    if (urlNameList.length) {
      const mediaCreateReqs: any[] = []
      urlNameList.forEach((url, index) => {
        // 언어별 값 선택
        const languageMap = {}
        currentProject?.languageList.forEach((lang) => {
          languageMap[lang] = {
            name: url,
            description: '',
          }
        })

        const mediaReq = {
          type: 'URL',
          languageMap,
          fileId: null,
          value: url,
        }
        mediaCreateReqs.push(mediaReq)
      })

      await createMultiMedia(currentProject.uid, mediaCreateReqs).then(
        (res) => {
          res.data.forEach((mediaId, mIdx) => {
            mediaIdListByFileName[urlNameList[mIdx]] = mediaId
          })
        }
      )
    }

    await reqContentsArr.forEach((r) => {
      r.cellList
        .filter((c) => c.type === 'MEDIA')
        .forEach((cm) => {
          const cellMediaIdList: any = []

          cm.tmpValue.forEach((f) => {
            if (mediaIdListByFileName[f.trim()])
              cellMediaIdList.push(mediaIdListByFileName[f.trim()])
          })

          cm.mediaIdList = cellMediaIdList
        })
    })

    // 연관 콘텐츠 ID 가져오기
    titleUidList = titleUidList.filter((v, i, a) => a.indexOf(v) === i)
    const modelsWithTitle = modelList.filter((model) => model.hasTitle)

    const promises: any = modelsWithTitle.map(async (model, index) => {
      const keymapByModel = await getContentsUidKeyMapByTitle(model)
      relationUidKeyMap = { ...relationUidKeyMap, ...keymapByModel }

      reqContentsArr.forEach((r) => {
        r.cellList
          .filter((c) => c.type === 'RELATION')
          .forEach((cm) => {
            const cellRelationUidList: any = cm.relationUidList

            cm.tmpValue.forEach((f) => {
              if (keymapByModel && keymapByModel[f.trim()])
                cellRelationUidList.push(keymapByModel[f.trim()])
            })

            cm.relationUidList = cellRelationUidList
          })
      })
    })

    await Promise.all(promises)

    try {
      const contentsReqs: any[] = []
      const len = reqContentsArr.length
      const loop = Math.ceil(len / CHUNK_SHEET_UPLOAD_SIZE)

      for (let i = 0; i < loop; i++) {
        const startIdx = i * CHUNK_SHEET_UPLOAD_SIZE
        const subReqContentsArr = reqContentsArr.slice(
          startIdx,
          startIdx + CHUNK_SHEET_UPLOAD_SIZE
        )

        const resAxios = await createContentsList(
          currentProject.uid,
          currentModel.id,
          subReqContentsArr
        )
        contentsReqs.push(resAxios)
      }

      axios.all([...contentsReqs]).then(
        axios.spread(async (...responses) => {
          // 신규 uid 목록
          const newUidArr = responses.map((r) => r.data.list).flat()

          // 신규 업로드 콘텐츠 uid 연결
          const newContents: ContentsInterface[] = []

          newUidArr.forEach((uid, idx) => {
            newContents.push({
              uid: uid,
              cellList: reqContentsArr[idx].cellList,
              date: {
                createdAt: moment().format('YYYY-MM-DDTHH:mm:ss'),
              },
            })
          })

          // 콘텐츠 설정
          const updatedAllContents = importReplace
            ? newContents
            : [...allContentsList, ...newContents]

          /* dispatch(
            setContentsList(
              updatedAllContents,
              contentsPagination,
              contentsKeyword,
              contentsSort,
              true
            )
          ) */

          // IndexedDB 가져오기
          const indexedDBContents = await getItem(currentModel.id)

          setTimeout(() => {
            const updatedAllContentsCompressed =
              JSON.stringify(updatedAllContents)

            if (indexedDBContents && indexedDBContents !== -1) {
              updateItem(currentModel.id, updatedAllContentsCompressed)
            } else {
              addItem(currentModel.id, updatedAllContentsCompressed)
            }

            dispatch(setContentsListLoading(false))
            message.destroy()
            message.success(t('uploadSuccess'))
            dispatch(getProjectUsage(currentProject?.uid as string))
            dispatch(setContentsListReload(true))
            // window.location.reload()
          })
        })
      )
    } catch (e) {
      message.destroy()

      // @ts-ignore
      message.error(e.response.data.message)
      dispatch(setContentsListLoading(false))
    }

    if (fileSheet) {
      fileSheet.value = ''
    }
  }

  /**
   * 샘플 시트 다운로드
   * @returns
   */
  const exportSampleSheet = () => {
    if (!currentProject || !currentModel) return

    const workbook = utils.book_new()

    // Headers
    const cellHeaders = flattenComponentList
      .filter((fc) => fc.exportable)
      .map((fc) =>
        (fc.languageMap[currentProject.defaultLang] as string)
          .normalize('NFC')
          .replace('\b', '')
      )

    currentProject.languageList.forEach((lang) => {
      // Sample Row
      const sampleRow: any[] = []
      flattenComponentList
        .filter((fc) => fc.exportable)
        .forEach((fc) => {
          let sample: any = ''
          if (fc.type === 'SINGLE_LINE_TEXT' || fc.type === 'LONG_LINE_TEXT') {
            sample = 'sample text'
          } else if (fc.type === 'RICH_TEXT') {
            sample =
              'sample text <b>Bold text</b> <i>Italic text</i> <code>Code text</code>'
          } else if (fc.type === 'EMAIL') {
            sample = 'sample@email.com'
          } else if (fc.type === 'BOOLEAN') {
            sample = 'O'
          } else if (fc.type === 'DATE') {
            sample =
              fc.option?.dateFormats &&
              dateFormatsMoment[fc.option?.dateFormats]
                ? dateFormatsMoment[fc.option?.dateFormats]
                : fc.option?.dateFormats
          } else if (fc.type === 'NUMBER') {
            sample = fc.option?.max ? fc.option?.max : 1
          } else if (fc.type === 'CATEGORY') {
            sample =
              fc.selectorList && fc.selectorList.length
                ? fc.selectorList[0].languageMap[currentProject.defaultLang]
                : ''
          } else if (fc.type === 'PASSWORD') {
            sample = 'sample text'
          } else if (fc.type === 'MEDIA') {
            sample = fc.option?.multipleMedia
              ? 'filename1.jpg, filename2.jpg'
              : 'filename.jpg'
          } else if (fc.type === 'RELATION') {
            sample = fc.option?.multiple
              ? 'contents title1, contents title2'
              : 'contents title'
          }

          sampleRow.push(sample)
        })

      const worksheet = utils.aoa_to_sheet([cellHeaders, sampleRow])
      worksheet['!cols'] = getColsWidth()

      utils.book_append_sheet(workbook, worksheet, lang)
    })

    writeFile(
      workbook,
      currentProject?.name +
        '_' +
        currentModel?.languageMap[currentProject?.defaultLang] +
        '_sample.xlsx'
    )
  }

  /**
   * 키워드 검색
   * @param value
   */
  const onHandleEnterKeyword = (value) => {
    navigate(
      `/projects/${currentProject?.uid}/contents/${
        currentModel?.id
      }?page=1&size=${contentsPagination.pageSize}&sort=${contentsSort}&q=${
        value ? searchField + '||' + value.trim() : searchField
      }`
    )
  }

  /**
   * 다운로드 컨텐츠 메뉴 선택
   * @param val
   */
  const onHandleDownload = (val) => {
    if (val === 'downloadAll') {
      Modal.confirm({
        centered: true,
        title: t('massContentsDownloadWarningTitle'),
        icon: <ExclamationCircleOutlined />,
        content: t('massContentsDownloadWarningDesc'),
        okText: t('download'),
        cancelText: t('cancel'),
        onOk() {
          downloadAllContents()
        },
        onCancel() {
          // setImportReplace(false)
        },
      })
    } else if (val === 'downloadSelected') {
      downloadSelectedContents()
    }

    setDownloadContents(null)
  }

  /**
   * 전체 콘텐츠 엑셀 다운로드
   */
  const downloadAllContents = () => {
    downloadExcel(allContentsList)
    dispatch(setContentsListLoading(false))
  }

  /**
   * 선택한 콘텐츠 엑셀 다운로드
   * @returns
   */
  const downloadSelectedContents = () => {
    if (!currentProject || !currentModel) return

    if (checkedContentsList.length === 0) {
      alert(t('selectContentsToBeDownloaded'))
      return
    }

    downloadExcel(checkedContentsList)
  }

  /**
   * 엑셀 다운로드
   * @param contentsList
   * @returns
   */
  const downloadExcel = (contentsList) => {
    if (!currentProject || !currentModel) return

    const workbook = utils.book_new()

    // Headers
    const cellHeaders = flattenComponentList
      .filter((fc) => fc.exportable)
      .map((fc) =>
        (fc.languageMap[currentProject.defaultLang] as string)
          .normalize('NFC')
          .replace('\b', '')
      )

    currentProject.languageList.forEach((lang) => {
      // Contents List Row
      const contentsListRow: any[] = []

      contentsList.forEach((contents) => {
        // Contents Row
        const contentsRow: any[] = []

        flattenComponentList
          .filter((fc) => fc.exportable)
          .forEach((fc) => {
            const cell =
              contents.cellList &&
              contents.cellList.find((cl) => cl.component.id === fc.id)

            let cellValue: any = ''

            if (cell) {
              if (
                fc.type === 'SINGLE_LINE_TEXT' ||
                fc.type === 'LONG_LINE_TEXT'
              ) {
                cellValue =
                  cell.languageMap && cell.languageMap[lang]
                    ? cell.languageMap[lang] + ''
                    : ''
              } else if (fc.type === 'RICH_TEXT') {
                cellValue = (cell.languageMap[lang] + '').replace(
                  /(<([^>]+)>)/gi,
                  ''
                )
              } else if (fc.type === 'EMAIL') {
                cellValue = cell.value
              } else if (fc.type === 'BOOLEAN') {
                cellValue = cell.value
              } else if (fc.type === 'DATE') {
                const dateFormats =
                  fc.option?.dateFormats &&
                  dateFormatsMoment[fc.option?.dateFormats]
                    ? dateFormatsMoment[fc.option?.dateFormats]
                    : fc.option?.dateFormats

                cellValue = cell.value
                  ? fc.option?.dateFormats !== 'time'
                    ? cell.value.substr(0, dateFormats.length)
                    : cell.value.substr(11)
                  : ''
              } else if (fc.type === 'NUMBER') {
                cellValue = cell.value
              } else if (fc.type === 'CATEGORY') {
                cellValue =
                  cell.selectorList && cell.selectorList.length
                    ? cell.selectorList
                        .map((s) => s.languageMap[currentProject.defaultLang])
                        .join(',')
                    : ''
              } else if (fc.type === 'PASSWORD') {
                cellValue = cell.value
              } else if (fc.type === 'MEDIA') {
                cellValue =
                  cell.mediaList && cell.mediaList.length
                    ? cell.mediaList
                        .map((m) =>
                          m.mediaType === 'FILE'
                            ? m.file.name
                            : m.mediaType === 'URL'
                            ? m.value
                            : ''
                        )
                        .join(',')
                    : ''
              }
            }

            contentsRow.push(cellValue)
          })

        contentsListRow.push(contentsRow)
      })

      contentsListRow.unshift(cellHeaders)

      const worksheet = utils.aoa_to_sheet(contentsListRow)
      worksheet['!cols'] = getColsWidth()
      utils.book_append_sheet(workbook, worksheet, lang)
    })

    writeFile(
      workbook,
      currentProject?.name +
        '_' +
        currentModel?.languageMap[currentProject?.defaultLang] +
        '.xlsx'
    )
  }

  /**
   * 컴포넌트 아이콘
   * @param type
   * @returns
   */
  const getIcon = (type) => {
    const component = availableComponents.find((c) => c.type === type)

    return component ? <component.icon /> : <></>
  }

  /**
   * 콘텐츠 내용 모두 가져오기
   * @param model
   * @returns
   */
  const getContentsUidKeyMapByTitle = async (model: ModelInterface) => {
    const relationUidListByTitle = {}

    const componentWithTitle = model.flattenComponentList?.find(
      (c) => c.option?.title
    )

    // Title 없는 모델은 검색생략
    if (!componentWithTitle || !currentProject) return

    try {
      const contentsListReqs: any[] = []

      const getData = (page: number) => {
        const resAxios = getContentsListSearch(currentProject?.uid, model.id, {
          page: page,
          size: CHUNK_CONTENTS_GET_SIZE,
          direction: 'ASC',
        })
        contentsListReqs.push(resAxios)

        return resAxios.then((res) => {
          if (!res.data.pageInfo.isLast) {
            return getData(page + 1)
          }
        })
      }

      await getData(0)

      await axios.all([...contentsListReqs]).then(
        axios.spread(async (...responses) => {
          const contentsList = responses.map((r) => r.data.list).flat()

          contentsList.forEach((contents: ContentsInterface) => {
            const titleCell = contents.cellList.find(
              (cell) => cell.component?.id === componentWithTitle.id
            )

            if (titleCell && titleCell.languageMap) {
              relationUidListByTitle[
                titleCell.languageMap[currentProject.defaultLang as string]
              ] = contents.uid
            }
          })
        })
      )

      return relationUidListByTitle
    } catch (e) {
      // console.log(e)
      return false
    }
  }

  return currentProject ? (
    <div className="w-full flex justify-between items-center ">
      <div className="flex bg-white border border-gray-300 rounded-sm">
        <Select
          placeholder={t('searchField')}
          bordered={false}
          style={{
            width: 240,
          }}
          value={searchField}
          disabled={contentsListLoading}
          onChange={setSearchField}>
          <Select.Option value={''} key={''}>
            {t('all')}
          </Select.Option>
          {flattenComponentList
            .filter((fc) => fc.searchable)
            .map((fc) => (
              <Select.Option value={fc.id + ''} key={fc.id + ''}>
                <div className="flex space-x-2">
                  <span className="-mt-0.5">{getIcon(fc.type)}</span>
                  <span>{fc.languageMap[currentProject.defaultLang]}</span>
                </div>
              </Select.Option>
            ))}
        </Select>
        <Input.Search
          bordered={false}
          placeholder={t('search')}
          title={t('search')}
          className={'border-l border-gray-300'}
          enterButton
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
          allowClear
          disabled={contentsListLoading}
          onSearch={(value) => onHandleEnterKeyword(value)}
          // @ts-ignore
          onPressEnter={(e) => onHandleEnterKeyword(e.target.value)}
        />
      </div>
      <div>
        <div className="flex space-x-2">
          {/* {currentProject.role !== 'VIEWER' ? (
            <Select
              placeholder={t('massUploadContents')}
              style={{ width: 180 }}
              value={massContents}
              disabled={contentsListLoading}
              onChange={onHandleSheet}>
              <Select.Option value="uploadReplace">
                {t('uploadContents')} ({t('uploadContentsReplace')})
              </Select.Option>
              <Select.Option value="uploadAppend">
                {t('uploadContents')} ({t('uploadContentsAppend')})
              </Select.Option>
              <Select.Option value="downloadSample">
                {t('downloadSamplesMassContents')}
              </Select.Option>
            </Select>
          ) : (
            <></>
          )} */}
          <Select
            placeholder={t('download')}
            style={{ width: 180 }}
            value={downloadContents}
            disabled={contentsListLoading}
            onChange={onHandleDownload}>
            <Select.Option value="downloadAll">
              {t('downloadAll')}
            </Select.Option>
            <Select.Option value="downloadSelected">
              {t('downloadSelected')}
            </Select.Option>
          </Select>
          <input
            id="file-sheet"
            type="file"
            className="hidden"
            onChange={(e) => importSheet(e)}
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          />
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}
