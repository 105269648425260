import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { useNavigate, useParams } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Layout, Button, Tooltip } from 'antd'
import {
  PlusCircleOutlined,
  ApiOutlined,
  InfoCircleOutlined,
  CloudSyncOutlined,
  HolderOutlined,
} from '@ant-design/icons'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import axios from 'axios'
import { RootState } from '@/states/reducers'
const { Sider } = Layout
import {
  LAYOUT_DEFAULT_BG,
  DEFAULT_CONTENTS_PAGE_SIZE,
  LAYOUT_WARNING_COLOR,
  LAYOUT_SUCCESS_COLOR,
  LAYOUT_OKAY_COLOR,
} from '@/configs'
import { getSavedContentsSortKey } from '@/utils/helpers'
import { setModelFormModal } from '@/states/actions/modals.actions'
import { useAppDispatch } from '@/states/store'
import {
  getModels,
  setCurrentModel,
  setModelsList,
  updateModel,
} from '@/states/actions'

export const LayoutSidebarProjectContents = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  // Params
  let { modelId } = useParams()

  // State (Redux)
  const { layoutState, projectsState } = useSelector(
    (state: RootState) => ({
      layoutState: state.layout,
      projectsState: state.projects,
    }),
    shallowEqual
  )
  const { sidebar } = layoutState
  const { modelList, currentModel, currentProject, contentsListLoading } =
    projectsState

  /**
   * 모델 변경
   * @param e
   * @returns
   */
  const onModelChange = (model) => {
    if (contentsListLoading) return

    const targetModelId = model.id
    const targetModel = modelList.find((m) => m.id === targetModelId)

    if (!targetModel || Number(modelId) === targetModel.id) return

    navigate(
      `/projects/${currentProject?.uid}/contents/${
        targetModel.id
      }?page=1&size=${DEFAULT_CONTENTS_PAGE_SIZE}&sort=${getSavedContentsSortKey(
        targetModel.id
      )}&q=`
    )
  }

  /**
   * 모델 순서 변경
   * @param result
   * @returns
   */
  const onSortEnd = async (result) => {
    if (!result.destination || contentsListLoading) {
      return
    }

    // Sort 실행
    const updateModels = JSON.parse(JSON.stringify(modelList))
    const startIndex = result.source.index
    const endIndex = result.destination.index
    const [removed] = updateModels.splice(startIndex, 1)
    updateModels.splice(endIndex, 0, removed)
    dispatch(setModelsList(updateModels))

    const categoryReqs: any[] = []
    await updateModels.forEach((model, idx) => {
      model.order = idx

      categoryReqs.push(updateModel(currentProject?.uid, model.id, model))
    })

    axios.all([...categoryReqs]).then(
      axios.spread((...responses) => {
        setTimeout(() => {
          // dispatch(getModels(currentProject?.uid, currentModel?.id))
        })
      })
    )
  }

  return (
    <Sider
      theme={'light'}
      width={'auto'}
      className={'border-r border-gray-300 overflow-hidden overflow-y-auto'}
      style={{
        height: '100vh',
        backgroundColor: LAYOUT_DEFAULT_BG,
        zIndex: 100,
      }}>
      <h4
        className={
          'py-3 px-6 text-xs leading-5 mb-0 flex justify-between items-center'
        }>
        <span className="font-bold uppercase">{t('models')}</span>
        {currentProject?.usage?.type !== 'UNLIMITED' &&
        currentProject?.usage?.model ? (
          <Tooltip title={t('noModelsDesc')} className="cursor-help">
            <span
              className="font-normal flex space-x-1 items-center"
              style={{
                color:
                  currentProject?.usage?.model.current >=
                  currentProject?.usage?.model.limit
                    ? LAYOUT_WARNING_COLOR
                    : LAYOUT_OKAY_COLOR,
              }}>
              <span>
                {currentProject?.usage?.model.current} /{' '}
                {currentProject?.usage?.model.limit}
              </span>
              <InfoCircleOutlined className="mt-px" />
            </span>
          </Tooltip>
        ) : (
          <></>
        )}
      </h4>
      <div>
        {currentProject ? (
          <DragDropContext onDragEnd={onSortEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  className="ant-menu ant-menu-root ant-menu-inline ant-menu-light"
                  role="menu"
                  style={{
                    backgroundColor: 'transparent',
                  }}
                  {...provided.droppableProps}
                  ref={provided.innerRef}>
                  {modelList.map((model, index) => (
                    <Draggable
                      key={model.id}
                      draggableId={(model.id as number).toString()}
                      index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}>
                          <div
                            className={`ant-menu-item ant-menu-item-only-child  ${
                              currentModel && model.id === currentModel.id
                                ? 'ant-menu-item-selected'
                                : ''
                            } ${
                              contentsListLoading
                                ? 'ant-menu-item-disabled'
                                : ''
                            }`}
                            style={{
                              paddingLeft: '24px',
                            }}
                            role="menuitem"
                            aria-disabled={contentsListLoading}
                            onClick={() => onModelChange(model)}>
                            <div className="ant-menu-title-content">
                              <div className="flex items-center space-x-2 ">
                                <div className="grow truncate flex items-center space-x-2">
                                  <span>
                                    {
                                      model.languageMap[
                                        currentProject.defaultLang
                                      ]
                                    }
                                  </span>
                                  {model.lastValidate ? (
                                    <CloudSyncOutlined
                                      style={{
                                        color: LAYOUT_SUCCESS_COLOR,
                                      }}
                                    />
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                {model.hasTitle ? (
                                  <div className="flex-none">
                                    <ApiOutlined
                                      title={t('availableRelationConnected')}
                                    />
                                  </div>
                                ) : (
                                  <></>
                                )}
                                <div {...provided.dragHandleProps}>
                                  <HolderOutlined
                                    className="cursor-move"
                                    title={t('changeOrder')}
                                  />
                                </div>
                              </div>
                            </div>
                            {provided.placeholder}
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                  <div
                    className={`ant-menu-item ant-menu-item-only-child cursor-pointer ${
                      contentsListLoading ? 'ant-menu-item-disabled' : ''
                    }`}
                    onClick={() => dispatch(setModelFormModal(true))}
                    aria-disabled={contentsListLoading}
                    style={{
                      paddingLeft: '24px',
                    }}>
                    {/* <Button
                      type={'link'}
                      icon={<PlusCircleOutlined />}
                      disabled={
                        currentProject?.usage &&
                        modelList.length >= currentProject.usage.model.limit
                      }
                      style={{
                        padding: 0,
                      }}>
                      {t('addModel')}
                    </Button> */}
                  </div>
                </div>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <></>
        )}
      </div>
    </Sider>
  )
}
