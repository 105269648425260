import React, { useEffect } from 'react'
import { BrowserRouter, Route } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store } from '@/states/store'
import { Helmet } from 'react-helmet'
import TagManager from 'react-gtm-module'

import PrivateRoute from './components/common/common.protectedRoute'
import CustomRoute from './components/common/common.customRoute'
import Home from './pages/Home'
import LoginPage from './pages/auth/Login'
import SignUpPage from './pages/auth/Signup'
import ProjectsIndexPage from './pages/projects/ProjectsIndex'
import NotFound from './pages/404'
import ForgetPassword from './pages/auth/ForgetPassword'
import ResetPassword from './pages/auth/ResetPassword'
import ProjectsContentsByModel from './pages/projects/ProjectsContentsByModel'
import ProjectsDashboard from './pages/projects/ProjectsDashboard'
import ProjectsContentsBuilder from './pages/projects/ProjectsContentsBuilder'
import ProjectsMediaContents from './pages/projects/ProjectsMediaContents'
import SettingsAccount from './pages/settings/SettingsAccount'
import ProjectsSettingsIndex from './pages/projects/ProjectsSettingsIndex'
import ProjectsSettingsMembers from './pages/projects/ProjectsSettingsMembers'
import ProjectsSettingsUsage from './pages/projects/ProjectsSettingsUsage'
import ProjectsDevelopersApps from './pages/projects/ProjectsDevelopersApps'
import ProjectsDevelopersTypescript from './pages/projects/ProjectsDevelopersTypescript'
import ProjectsDevelopersExternalAPIs from './pages/projects/ProjectsDevelopersExternalApis'

if (process.env.REACT_APP_GOOGLE_TAG_ID) {
  TagManager.initialize({
    gtmId: process.env.REACT_APP_GOOGLE_TAG_ID,
  })
}

const App = () => {
  return (
    <Provider store={store}>
      <Helmet>
        {!!process.env.REACT_APP_STATUSPAGE_ID && (
          <script
            src={`https://${process.env.REACT_APP_STATUSPAGE_ID}.statuspage.io/embed/script.js`}
          />
        )}
      </Helmet>
      <BrowserRouter>
        <CustomRoute>
          <Route index path="/" element={<Home />} />
          <Route element={<PrivateRoute authentication={false} />}>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/signup" element={<SignUpPage />} />
            <Route path="/password/forget" element={<ForgetPassword />} />
            <Route path="/password/reset" element={<ResetPassword />} />
          </Route>
          <Route
            path="/settings"
            element={<PrivateRoute authentication={true} />}>
            <Route path="account" element={<SettingsAccount />} />
          </Route>
          <Route
            path="/projects"
            element={<PrivateRoute authentication={true} />}>
            <Route path="" element={<ProjectsIndexPage />} />
            <Route path=":projectUid">
              <Route path="" element={<ProjectsDashboard />} />
              {/* <Route path="builder" element={<ProjectsContentsBuilder />} />
              <Route
                path="builder/:modelId"
                element={<ProjectsContentsBuilder />}
              /> */}
              <Route
                path="contents/:modelId"
                element={<ProjectsContentsByModel />}
              />
              <Route path="media" element={<ProjectsMediaContents />} />
              <Route path="settings">
                <Route path="" element={<ProjectsSettingsIndex />} />
                <Route path="members" element={<ProjectsSettingsMembers />} />
                <Route path="usage" element={<ProjectsSettingsUsage />} />
              </Route>
              {/* <Route path="developers">
                <Route path="apps" element={<ProjectsDevelopersApps />} />
                <Route
                  path="externalAPIs"
                  element={<ProjectsDevelopersExternalAPIs />}
                />
                <Route
                  path="typescript"
                  element={<ProjectsDevelopersTypescript />}
                />
              </Route> */}
            </Route>
          </Route>
          <Route path="*" element={<NotFound />} />
        </CustomRoute>
      </BrowserRouter>
      {process.env.REACT_APP_ENV === 'dev' ? (
        <div
          className="fixed left-6 leading-6 text-red-500 font-bold text-xl -mt-6"
          style={{
            top: '50%',
            zIndex: 9999,
          }}>
          DEVELOPMENT
          <br />
          개발, 테스트용
        </div>
      ) : (
        <></>
      )}
    </Provider>
  )
}

export default App
