import React, { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Modal, Button, Typography, Upload, message, notification } from 'antd'
import {
  CheckOutlined,
  CloseCircleOutlined,
  InboxOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import { RootState } from '@/states/reducers'
import { setMediaUploadModal } from '@/states/actions/modals.actions'
import {
  FILE_IMAGE_MAX_RES,
  FILE_MAX_COUNT_UPLOAD,
  FILE_MAX_SIZE_UPLOAD,
  LAYOUT_MODAL_WIDTH,
} from '@/configs'
import { CancelTokenSource } from 'axios'
import { uploadFile } from '@/states/actions/files.actions'
import {
  createMedia,
  setNewMediaList,
  getMedia,
  createMultiMedia,
} from '@/states/actions/media.actions'
import axios from 'axios'
import { UploadFile } from 'antd/lib/upload/interface'
import { MediaInterface } from '@/types'
import { getProjectUsage } from '@/states/actions/projects.actions'
import { humanFileSize } from '@/utils/helpers'
import { useAppDispatch } from '@/states/store'

let cancelToken: CancelTokenSource

interface MediaUploadProps {
  onUpload?: () => void
}

export const MediaUploadModal = ({ onUpload }: MediaUploadProps) => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  // State (Redux)
  const { projectsState, modalsState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
      modalsState: state.modals,
    }),
    shallowEqual
  )
  const { currentProject } = projectsState
  const { mediaUploadModal, mediaUploadUid } = modalsState

  // State
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (!mediaUploadModal) {
      setLoading(false)

      if (typeof cancelToken !== typeof undefined) {
        cancelToken.cancel()
      }
    }
  }, [mediaUploadModal])

  // Upload props
  const uploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file)
      const newFileList = fileList.slice()
      newFileList.splice(index, 1)

      setFileList(newFileList)
    },
    beforeUpload: (file) => {
      // 개당 최 파일 크기 체크
      /* if (file.size > FILE_MAX_SIZE_UPLOAD * 1024) {
        message.error(
          t('error.fileTooBig', {
            name: file.name,
            size: FILE_MAX_SIZE_UPLOAD / 1024,
          })
        )
      } */

      return false
    },
    onChange: ({ file, fileList }) => {
      // 프로젝트 미디어 스토리지 체크
      if (
        currentProject &&
        currentProject.usage &&
        currentProject.price !== 'UNLIMITED' &&
        fileList.map((a) => a.size).reduce((a, b) => a + b) +
          currentProject?.usage?.storage.current >
          currentProject?.usage?.storage.limit
      ) {
        message.error(
          t('error.mediaStorageShortage', {
            current: humanFileSize(currentProject?.usage?.storage.current),
            max: humanFileSize(currentProject?.usage?.storage.limit),
          })
        )
        return false
      }

      /* const reader = new FileReader()

      reader.onload = (e) => {
        if (e.target && e.target.result) {
          // 파일 크기 체크
          if (file.size > FILE_MAX_SIZE_UPLOAD * 1024) {
            message.error(
              t('error.fileTooBig', {
                name: file.name,
                size: FILE_MAX_SIZE_UPLOAD / 1024,
              })
            )
            return false
          }

          // 이미지의 경우 해상도 확인
          if (file.type.indexOf('image') >= 0) {
            const image = new Image()

            // @ts-ignore
            image.src = e.target.result
            image.onload = function () {
              // @ts-ignore
              const height = this.height
              // @ts-ignore
              const width = this.width

              if (width > FILE_IMAGE_MAX_RES || height > FILE_IMAGE_MAX_RES) {
                message.error(
                  t('error.imageSizeTooBig', {
                    name: file.name,
                    px: FILE_IMAGE_MAX_RES,
                  })
                )
                return false
              } else {
                setFileList((prevFileList) => [...prevFileList, file])
              }

              return true
            }
          } else {
            setFileList((prevFileList) => [...prevFileList, file])
          }
        }
      }
      reader.readAsDataURL(file) */
      setFileList((prevFileList) => [...prevFileList, file])
    },
    fileList: fileList,
  }

  /**
   * 파일 업로드
   */
  const uploadFiles = () => {
    if (fileList.length) {
      // 프로젝트 미디어 스토리지 체크

      // Cancel duplicated requests
      if (typeof cancelToken !== typeof undefined) {
        cancelToken.cancel()
      }
      cancelToken = axios.CancelToken.source()

      setLoading(true)

      notification.destroy()
      notification.open({
        message: t('uploading'),
        icon: <LoadingOutlined spin />,
        placement: 'bottomRight',
        duration: 0,
      })

      const req = new FormData()

      fileList.forEach(async (file) => {
        // @ts-ignore
        var fileNew = new File([file], file.name.normalize('NFC'), {
          type: file.type,
        })

        // @ts-ignore
        req.append('files', fileNew)
      })

      uploadFile(req, cancelToken)
        .then(async (res) => {
          const createMediaReqs: any[] = []

          res.data.forEach(async (newFile) => {
            // 언어별 값 선택
            const languageMap = {}
            currentProject?.languageList.forEach((lang) => {
              languageMap[lang] = {
                name: newFile.value
                  .replace(/\.[^/.]+$/, '')
                  .normalize('NFC')
                  .trim(),
              }
            })

            createMediaReqs.push({
              type: 'FILE',
              fileId: newFile.fileId,
              value: newFile.value,
              languageMap,
            })
          })

          createMultiMedia(currentProject?.uid, createMediaReqs)
            .then(async (res) => {
              await setTimeout(() => {
                setLoading(false)
                setFileList([])
                dispatch(setMediaUploadModal(false))
                dispatch(getProjectUsage(currentProject?.uid as string))

                notification.destroy()
                message.success(t('saveSuccess'))

                // 미디어 불러오기
                const getMediaReqs: any[] = []

                res.data.forEach((mediaId) => {
                  const resAxios = getMedia(currentProject?.uid, mediaId)
                  getMediaReqs.push(resAxios)
                })

                axios
                  .all([...getMediaReqs])
                  .then(
                    axios.spread(async (...responses) => {
                      const uploadedMediaList: MediaInterface[] = responses.map(
                        (r) => r.data.data
                      )
                      dispatch(
                        setNewMediaList(uploadedMediaList, mediaUploadUid)
                      )
                      if (onUpload) onUpload()
                    })
                  )
                  .catch((errors) => {
                    // react on errors.
                  })

                if (onUpload) onUpload()
              })
            })
            .catch((errors) => {
              // react on errors.
            })
        })
        .catch((e) => {
          if (e?.response?.data) {
            message.error(e.response.data.error)
          } else {
            message.error(t('error.wrong'))
          }
          setLoading(false)
        })
    }
  }

  /**
   * 업로드 취소 확인
   */
  const confirmModalClose = () => {
    if (!loading || (loading && confirm(t('confirmFileUploadCancele')))) {
      dispatch(setMediaUploadModal(false))
    }
  }

  return (
    <Modal
      width={LAYOUT_MODAL_WIDTH}
      zIndex={1010}
      closeIcon={<CloseCircleOutlined title={t('close')} />}
      open={mediaUploadModal}
      maskClosable={false}
      onCancel={() => confirmModalClose()}
      title={t('uploadMediaContents')}
      footer={[
        <div key={'footer'} className={'flex justify-between items-center'}>
          <div>
            <Button type="primary" ghost onClick={() => confirmModalClose()}>
              {t('cancel')}
            </Button>
          </div>
          <div>
            <Button
              type={'primary'}
              icon={<CheckOutlined />}
              disabled={loading || fileList.length === 0}
              loading={loading}
              onClick={() => uploadFiles()}>
              {t('addMediaContents')}
            </Button>
          </div>
        </div>,
      ]}>
      <>
        <div className={'space-y-6'}>
          <div className={'border-b pb-1.5'}>
            <Typography.Title level={5} className={'mt-0 mb-3'}>
              {t('uploadMultipleMediaContents')}
            </Typography.Title>
          </div>
          <div className="block">
            <Upload.Dragger
              {...uploadProps}
              listType={'text'}
              maxCount={FILE_MAX_COUNT_UPLOAD}
              multiple={true}
              disabled={loading}>
              <div>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">{t('dragTitle')}</p>
                <p className="ant-upload-hint">
                  {t('dragDesc', { count: FILE_MAX_COUNT_UPLOAD })}
                  {/* <br />
                  {t('maxFileDesc', { size: FILE_MAX_SIZE_UPLOAD / 1024 })} */}
                </p>
              </div>
            </Upload.Dragger>
          </div>
        </div>
      </>
    </Modal>
  )
}
