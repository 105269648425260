import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { shallowEqual, useSelector } from 'react-redux'
import {
  Modal,
  Button,
  Input,
  Row,
  Col,
  Tabs,
  Upload,
  message,
  Radio,
  notification,
} from 'antd'
import {
  CheckOutlined,
  CloseCircleOutlined,
  FileOutlined,
  PlusOutlined,
  ExpandOutlined,
  DownloadOutlined,
  DeleteFilled,
  LinkOutlined,
  ExportOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { CancelTokenSource } from 'axios'
import { RootState } from '@/states/reducers'
import { setMediaFormModal } from '@/states/actions/modals.actions'
import {
  FILE_IMAGE_MAX_RES,
  FILE_MAX_SIZE_UPLOAD,
  imageExtensions,
  LAYOUT_MODAL_WIDTH,
} from '@/configs'
import { AlertStatus } from '@/components/common/common.alert'
import { uploadFile } from '@/states/actions/files.actions'
import {
  createMedia,
  setNewMediaList,
  updateMedia,
  getMedia,
} from '@/states/actions/media.actions'
import { UploadFile } from 'antd/lib/upload/interface'
import { ContentsInterface, MediaInterface, MediaType } from '@/types'
import { getContentsItem } from '@/states/actions/contents.actions'
import axios from 'axios'
import { MediaFileInfo, MediaContentsList, MediaThumb } from './'
import { humanFileSize, vimeoParser, youtubeParser } from '@/utils/helpers'
import { getProjectUsage } from '@/states/actions/projects.actions'
import { useAppDispatch } from '@/states/store'

let cancelToken: CancelTokenSource

const initialMediaFormValues = {
  KO: {
    name: '',
    description: '',
  },
  EN: {
    name: '',
    description: '',
  },
  JP: {
    name: '',
    description: '',
  },
  CN: {
    name: '',
    description: '',
  },
  value: '',
}

interface MediaChangeProps {
  onChangeMedia?: () => void
}

export const MediaFormModal = ({ onChangeMedia }: MediaChangeProps) => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  // State (Redux)
  const { projectsState, modalsState, authState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
      modalsState: state.modals,
      authState: state.auth,
    }),
    shallowEqual
  )
  const { currentProject, modelList } = projectsState
  const { mediaFormModal, mediaInfo, mediaUploadUid } = modalsState
  const { me } = authState

  // State
  const [mediaType, setMediaType] = useState<MediaType>('FILE')
  const [isFileRemove, setIsFileRemove] = useState<boolean>(true)
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [showPreview, setShowPreview] = useState<boolean>(false)
  const [previewUrl, setPreviewUrl] = useState<string>('')
  const [connectedContentsList, setConnectedContentsListFileList] = useState<
    ContentsInterface[]
  >([])

  // Effect
  useEffect(() => {
    if (mediaFormModal) {
      setTimeout(() => {
        document.getElementById('mediaFormName')?.focus()
      })

      if (!mediaInfo) {
        formikMediaForm.validateForm()
      }
    } else {
      resetForm()
    }
  }, [mediaFormModal])

  useEffect(() => {
    if (mediaInfo) {
      setMediaType(mediaInfo.mediaType)

      currentProject?.languageList.map((lang) => {
        formikMediaForm.setFieldValue(
          lang + '.name',
          mediaInfo.languageMap[lang]?.name
        )
        formikMediaForm.setFieldValue(
          lang + '.description',
          mediaInfo.languageMap[lang]?.description
        )
      })

      if (mediaInfo.mediaType === 'FILE') {
        setIsFileRemove(false)
      } else if (mediaInfo.mediaType === 'URL') {
        formikMediaForm.setFieldValue('value', mediaInfo.value)
      }

      if (
        mediaInfo.contentList &&
        mediaInfo.contentList.length &&
        mediaInfo.modelList &&
        mediaInfo.modelList.length
      ) {
        const connectdListReqs: any[] = []
        const connectedList: ContentsInterface[] = []
        mediaInfo.contentList?.forEach((contents, idx) => {
          const resAxios = getContentsItem(
            currentProject?.uid,
            // @ts-ignore
            mediaInfo.modelList[idx].id,
            contents.uid
          )
          connectdListReqs.push(resAxios)
        })

        axios.all([...connectdListReqs]).then(
          axios.spread(async (...responses) => {
            setConnectedContentsListFileList(responses.map((r) => r.data.data))
          })
        )
      } else {
        setConnectedContentsListFileList([])
      }
    }
  }, [mediaInfo])

  // Validation
  const validationMediaFormSchema = Yup.object().shape({
    // name: Yup.string().required(t('validation.required')),
  })

  // Formik
  const formikMediaForm = useFormik({
    initialValues: initialMediaFormValues,
    validationSchema: validationMediaFormSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      // 새 파일 업로드 확인
      if (mediaType === 'FILE' && isFileRemove && fileList.length === 0) {
        message.error(t('error.noFile'))
        return false
      }

      // 프로젝트 미디어 스토리지 체크
      if (
        currentProject &&
        currentProject.usage &&
        currentProject.price !== 'UNLIMITED' &&
        fileList &&
        fileList[0] &&
        (fileList[0].size as number) + currentProject?.usage?.storage.current >
          currentProject?.usage?.storage.limit
      ) {
        message.error(
          t('error.mediaStorageShortage', {
            current: humanFileSize(currentProject?.usage?.storage.current),
            max: humanFileSize(currentProject?.usage?.storage.limit),
          })
        )
        return false
      }

      // Cancel duplicated requests
      if (typeof cancelToken !== typeof undefined) {
        cancelToken.cancel()
      }
      cancelToken = axios.CancelToken.source()

      setStatus(null)
      setLoading(true)

      // 언어별 값 선택
      const languageMap = {}
      currentProject?.languageList.forEach((lang) => {
        languageMap[lang] = {
          name: values[lang].name
            ? values[lang].name.normalize('NFC').trim()
            : '',
          description: values[lang].description
            ? values[lang].description.normalize('NFC').trim()
            : '',
        }
      })

      const req = {
        type: mediaType,
        languageMap,
        fileId:
          mediaType === 'FILE' && mediaInfo?.file
            ? (mediaInfo?.file.id as number)
            : null,
        value: mediaType === 'FILE' ? null : '',
      }

      if (mediaType === 'FILE' && isFileRemove) {
        notification.destroy()
        notification.open({
          message: t('uploading'),
          icon: <LoadingOutlined spin />,
          placement: 'bottomRight',
          duration: 0,
        })

        // 새 파일 업로드
        const reqImg = new FormData()

        if (fileList && fileList.length) {
          const fileTmp = fileList[0]

          // @ts-ignore
          const fileNew = new File([fileTmp], fileTmp.name.normalize('NFC'), {
            type: fileTmp.type,
          })

          // @ts-ignore
          reqImg.append('files', fileNew)

          await uploadFile(reqImg, cancelToken)
            .then((res) => {
              req.fileId = res.data[0].fileId
              req.value = res.data[0].value
            })
            .catch((e) => {
              message.error(t('error.wrong'))
            })
        }
      }

      if (mediaType === 'URL') {
        req.value = values.value
      }

      if (mediaInfo) {
        await updateMedia(currentProject?.uid, mediaInfo?.id, req)
          .then(async (res) => {
            notification.destroy()
            message.success(t('saveSuccess'))

            await setTimeout(() => {
              getMedia(currentProject?.uid, mediaInfo?.id).then((res) => {
                dispatch(setMediaFormModal(false, null, res.data.data))
                dispatch(getProjectUsage(currentProject?.uid as string))

                if (onChangeMedia) onChangeMedia()
              })
            }, 1000)
          })
          .catch((e) => {
            message.error(e.response.data.error)
            setLoading(false)
          })
      } else {
        await createMedia(currentProject?.uid, req)
          .then(async (res) => {
            notification.destroy()
            message.success(t('saveSuccess'))

            await setTimeout(() => {
              getMedia(currentProject?.uid, res.data.data).then((res) => {
                dispatch(setMediaFormModal(false, null, res.data.data))

                const uploadedMediaList: MediaInterface[] = [res.data.data]

                dispatch(getProjectUsage(currentProject?.uid as string))
                dispatch(setNewMediaList(uploadedMediaList, mediaUploadUid))
                if (onChangeMedia) onChangeMedia()
              })
            }, 1000)
          })
          .catch((e) => {
            message.error(e.response.data.error)
            setLoading(false)
          })
      }
    },
  })

  // Upload props
  const uploadProps = {
    onRemove: (file) => {
      setFileList([])
    },
    beforeUpload: (file) => {
      /* const reader = new FileReader()

      reader.onload = (e) => {
        if (e.target && e.target.result) {
          const fileAdded = file

          // 프로젝트 미디어 스토리지 체크
          if (
            currentProject &&
            currentProject.usage &&
            currentProject.price !== 'UNLIMITED' &&
            file.size + currentProject?.usage?.storage.current >
              currentProject?.usage?.storage.limit
          ) {
            message.error(
              t('error.mediaStorageShortage', {
                current: humanFileSize(currentProject?.usage?.storage.current),
                max: humanFileSize(currentProject?.usage?.storage.limit),
              })
            )
            return false
          }

          // 파일 크기 체크
          if (file.size > FILE_MAX_SIZE_UPLOAD * 1024) {
            message.error(
              t('error.fileTooBig', {
                name: file.name,
                size: FILE_MAX_SIZE_UPLOAD / 1024,
              })
            )
            return false
          }

          // 이미지의 경우 해상도 확인
          if (file.type.indexOf('image') >= 0) {
            const image = new Image()

            // @ts-ignore
            image.src = e.target.result
            image.onload = function () {
              // @ts-ignore
              const height = this.height
              // @ts-ignore
              const width = this.width

              if (width > FILE_IMAGE_MAX_RES || height > FILE_IMAGE_MAX_RES) {
                message.error(
                  t('error.imageSizeTooBig', {
                    name: file.name,
                    px: FILE_IMAGE_MAX_RES,
                  })
                )
                return false
              } else {
                const fileAdded = file
                fileAdded.status = 'done'
                // @ts-ignore
                fileAdded.url = e.target.result
                setFileList([fileAdded])
              }

              return true
            }
          } else {
            const fileAdded = file
            fileAdded.status = 'done'
            fileAdded.url = e.target.result
            setFileList([fileAdded])
          }
        }
      }
      reader.readAsDataURL(file) */
      setFileList([file])

      return false
    },
    fileList,
  }

  /**
   * 폼 리셋
   */
  const resetForm = () => {
    formikMediaForm.resetForm()
    setLoading(false)
    setMediaType('FILE')
    setFileList([])
    setIsFileRemove(true)
    setShowPreview(false)
    setConnectedContentsListFileList([])

    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel()
    }
  }

  /**
   * 이미지 프리뷰
   * @param path
   */
  const onHandlePreview = (path) => {
    window.open(path, '_blank')
  }

  /**
   * 다운로드
   * @param active
   */
  const download = () => {
    if (!mediaInfo) return

    const source = mediaInfo.file.path
    const fileName = mediaInfo.file.name.normalize('NFC')

    var xhr = new XMLHttpRequest()
    xhr.open('GET', source, true)
    xhr.responseType = 'blob'
    xhr.onload = function () {
      var urlCreator = window.URL || window.webkitURL
      var imageUrl = urlCreator.createObjectURL(this.response)
      var tag = document.createElement('a')
      tag.href = imageUrl
      tag.download = fileName
      document.body.appendChild(tag)
      tag.click()
      document.body.removeChild(tag)
    }
    xhr.send()
  }

  /**
   * 파일 도구
   * @returns
   */
  const fileTools = () => {
    return (
      <div className="absolute top-3 right-3 z-10">
        <ul className="flex space-x-2">
          {mediaInfo &&
          mediaInfo.file &&
          mediaInfo &&
          mediaInfo.file.type === 'IMAGE' ? (
            <li
              className="cursor-pointer"
              onClick={() => onHandlePreview(mediaInfo.file.path)}>
              <div className="w-6 h-6 border border-gray-400 rounded-sm bg-white flex justify-center items-center">
                <ExpandOutlined title={t('expandFullSize')} />
              </div>
            </li>
          ) : (
            <></>
          )}
          <li className="cursor-pointer" onClick={() => download()}>
            <div className="w-6 h-6 border border-gray-400 rounded-sm bg-white flex justify-center items-center">
              <DownloadOutlined title={t('download')} />
            </div>
          </li>
          {currentProject?.role !== 'VIEWER' ? (
            <li
              className="cursor-pointer"
              onClick={() => setIsFileRemove(true)}>
              <div className="w-6 h-6 border border-gray-400 rounded-sm bg-white flex justify-center items-center">
                <DeleteFilled title={t('deleteFile')} />
              </div>
            </li>
          ) : (
            <></>
          )}
        </ul>
      </div>
    )
  }

  /**
   * 업로드 취소 확인
   */
  const confirmModalClose = () => {
    if (!loading || (loading && confirm(t('confirmFileUploadCancele')))) {
      dispatch(setMediaFormModal(false))
    }
  }

  return (
    <Modal
      width={LAYOUT_MODAL_WIDTH}
      zIndex={1010}
      closeIcon={<CloseCircleOutlined title={t('close')} />}
      open={mediaFormModal}
      maskClosable={false}
      onCancel={() => confirmModalClose()}
      title={
        mediaInfo
          ? mediaInfo.file
            ? mediaInfo.file.name
            : mediaInfo.value
          : t('addMediaContents')
      }
      footer={[
        <div key={'footer'} className={'flex justify-between items-center'}>
          <div>
            <Button type="primary" ghost onClick={() => confirmModalClose()}>
              {t('cancel')}
            </Button>
          </div>
          {currentProject?.role !== 'VIEWER' ? (
            <div>
              <Button
                type={'primary'}
                icon={<CheckOutlined />}
                onClick={() => formikMediaForm.submitForm()}
                disabled={
                  (mediaType === 'FILE' &&
                    isFileRemove &&
                    fileList.length === 0) ||
                  (mediaType === 'URL' && !formikMediaForm.values.value) ||
                  loading
                }
                loading={loading}>
                {t('save')}
              </Button>
            </div>
          ) : (
            <></>
          )}
        </div>,
      ]}>
      {currentProject ? (
        <>
          <form onSubmit={formikMediaForm.handleSubmit} method="POST">
            <AlertStatus
              status={formikMediaForm.status}
              onClick={() => formikMediaForm.setStatus(null)}></AlertStatus>
            <div className={'space-y-6'}>
              <Row gutter={24}>
                <Col span={12} className="space-y-6">
                  {!mediaInfo ? (
                    <div>
                      <Radio.Group
                        onChange={(e) => setMediaType(e.target.value)}
                        value={mediaType}
                        disabled={currentProject.role === 'VIEWER'}>
                        <Radio value={'FILE'}>{t('uploadFile')}</Radio>
                        <Radio value={'URL'}>{t('addUrl')}</Radio>
                      </Radio.Group>
                    </div>
                  ) : (
                    <></>
                  )}
                  {mediaType === 'FILE' ? (
                    isFileRemove ? (
                      <div>
                        <Upload
                          {...uploadProps}
                          listType={'picture-card'}
                          maxCount={1}
                          multiple={true}
                          disabled={loading}>
                          <div>
                            <p className="ant-upload-drag-icon">
                              <PlusOutlined />
                            </p>
                            <p className="ant-upload-text">Upload</p>
                          </div>
                        </Upload>
                        {/* <p className="mt-1">
                          {t('maxFileDesc', {
                            size: FILE_MAX_SIZE_UPLOAD / 1024,
                          })}
                        </p> */}
                      </div>
                    ) : (
                      <div className="relative">
                        {fileTools()}
                        {mediaInfo &&
                        mediaInfo.file &&
                        mediaInfo.file.type === 'IMAGE' ? (
                          <div className="w-full flex-none bg-gray-300 border">
                            <MediaThumb
                              noLazy
                              /* width={
                                mediaInfo.file.meta &&
                                mediaInfo.file.meta.width &&
                                mediaInfo.file.meta.width > 400
                                  ? '400'
                                  : mediaInfo.file.meta.width + ''
                              } */
                              media={mediaInfo}
                              textSize="3xl"
                              thumbnail="MEDIUM"
                            />
                          </div>
                        ) : (
                          <div
                            className={
                              'w-full aspect-w-10 aspect-h-8 bg-gray-300 flex-none flex items-center justify-center'
                            }>
                            {mediaInfo?.mediaType === 'FILE' ? (
                              <FileOutlined className="text-5xl absolute top-1/2 -mt-7" />
                            ) : (
                              <LinkOutlined className="text-5xl absolute top-1/2 -mt-7" />
                            )}
                          </div>
                        )}
                      </div>
                    )
                  ) : (
                    <></>
                  )}
                  {mediaType === 'URL' ? (
                    <div className="block">
                      <label htmlFor={`mediaFormNameValue`} className="">
                        <div className={'mb-2'}>
                          URL <span className="text-500">*</span>
                        </div>
                        <Input.Group compact>
                          <Input
                            id={'mediaFormNameValue'}
                            name={`value`}
                            type={'url'}
                            onChange={formikMediaForm.handleChange}
                            value={formikMediaForm.values.value}
                            readOnly={currentProject.role === 'VIEWER'}
                            style={{
                              width:
                                mediaInfo &&
                                mediaInfo.value === formikMediaForm.values.value
                                  ? `calc(100% - 2rem)`
                                  : '100%',
                            }}
                          />
                          {mediaInfo &&
                          mediaInfo.value === formikMediaForm.values.value ? (
                            <Button
                              icon={<ExportOutlined title={t('openNewTab')} />}
                              onClick={() => onHandlePreview(mediaInfo.value)}
                              title={t('openNewTab')}
                            />
                          ) : (
                            <></>
                          )}
                        </Input.Group>
                      </label>
                    </div>
                  ) : (
                    <></>
                  )}
                  {mediaInfo &&
                  mediaInfo.mediaType === 'URL' &&
                  (youtubeParser(mediaInfo.value) ||
                    vimeoParser(mediaInfo.value)) ? (
                    <MediaThumb media={mediaInfo} textSize="5xl" />
                  ) : (
                    <></>
                  )}
                  {mediaInfo && mediaType === 'FILE' && mediaInfo.file ? (
                    <MediaFileInfo mediaInfo={mediaInfo} />
                  ) : (
                    <></>
                  )}
                </Col>
                <Col span={12}>
                  <div className="space-y-4">
                    <Tabs defaultActiveKey={currentProject.defaultLang}>
                      {currentProject.languageList.map((lang) => (
                        <Tabs.TabPane
                          tab={t('lang.' + lang.toLocaleLowerCase())}
                          key={lang}
                          className={'space-y-6'}>
                          <div className="block">
                            <label
                              htmlFor={`mediaFormName${lang}`}
                              className="">
                              <div className={'mb-2'}>{t('title')}</div>
                              <Input
                                id={'mediaFormName' + lang}
                                name={`${lang}.name`}
                                type={'text'}
                                onChange={formikMediaForm.handleChange}
                                value={formikMediaForm.values[lang].name}
                                readOnly={currentProject.role === 'VIEWER'}
                              />
                            </label>
                          </div>
                          <div className="block">
                            <label
                              htmlFor={`mediaFormDesc${lang}`}
                              className="">
                              <div className={'mb-2'}>{t('description')}</div>
                              <Input.TextArea
                                id={'mediaFormDesc' + lang}
                                name={`${lang}.description`}
                                rows={5}
                                onChange={formikMediaForm.handleChange}
                                value={formikMediaForm.values[lang].description}
                                readOnly={currentProject.role === 'VIEWER'}
                              />
                            </label>
                          </div>
                        </Tabs.TabPane>
                      ))}
                    </Tabs>
                  </div>
                </Col>
              </Row>
              {/* 미디어 연결 콘텐츠: 시작 */}
              <MediaContentsList
                connectedContentsList={connectedContentsList}
              />
              {/* 미디어 연결 콘텐츠: 끝 */}
            </div>
          </form>
          <Modal
            width={'100%'}
            centered={true}
            open={showPreview}
            maskClosable={false}
            footer={null}
            onCancel={() => setShowPreview(false)}>
            <img src={previewUrl} className={'w-full'} />
          </Modal>
        </>
      ) : (
        <></>
      )}
    </Modal>
  )
}
